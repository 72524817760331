import React from "react";
import Layout from "../components/_App/layout";
import SEO from "../components/_App/seo"; 
import Navbar from "../components/Kinetik/Navbar";
import Footer from "../components/Kinetik/Footer";
import PageBanner from '../components/Common/PageBanner'; 
import ContactInfo from '../components/Kinetik/ContactInfo2';

import ContactForm2 from '../components/Kinetik/ContactForm2';

const Contact = () => (
    <Layout>
        <SEO title="Kontaktieren Sie uns" />

        <Navbar />

        <PageBanner pageTitle="Contact Us" />

        <ContactInfo />


        <ContactForm2 />
        
        <Footer /> 
    </Layout>
)

export default Contact;